.profile-container {
  background-image: url("../../assets/images/email-verify-bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.profile-card {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  height: auto;
  background-color: white;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 414px){
  .profile-container {
    background-image: none;
  }

  .profile-card {
    width: 100%;
    max-width: 100%;
  }

  .profile-firstname,
  .profile-lastname,
  .profile-mobile,
  .profile-otp,
  .gender-dropdown,
  .country-code-dropdown,
  .verify-button,
  .ok-button,
  .resend-button,
  .proceed-button,
  .teacher-button,
  .student-button {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 320px) {
  .profile-firstname,
  .profile-lastname,
  .profile-mobile,
  .profile-otp,
  .gender-dropdown,
  .country-code-dropdown {
    font-size: 14px;
    height: 35px;
  }

  .verify-button,
  .ok-button,
  .proceed-button,
  .teacher-button,
  .student-button {
    font-size: 14px;
    height: 40px;
  }

  .profile-logo {
    font-size: 25px;
  }
}

@media (min-width: 414px) and (max-width: 1400px) {
  .profile-container {
    padding: 5px;
  }

  .profile-logo {
    font-size: 24px;
  }

  .profile-card {
    width: 100%;
    padding: 10px;
  }

  .profile-firstname,
  .profile-lastname,
  .profile-mobile,
  .profile-otp,
  .gender-dropdown,
  .country-code-dropdown,
  .verify-button,
  .ok-button,
  .resend-button,
  .proceed-button,
  .teacher-button,
  .student-button {
    width: 100%;
    max-width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .verify-button,
  .proceed-button {
    width: 100%;
  }

  .ok-button {
    width: 100%;
  }

  .teacher-button,
  .student-button {
    width: 100%;
    margin-top: 8px;
  }

  .profile-mobile {
    width: 100%;
  }
}

.profile-logo {
  font-family: "Cocon";
  font-size: 50px;
  width: 100%;
  text-align: center;
  color: #6423f2;
  margin-bottom: 20px;
  margin-top: 0px;
}

.profile-firstname {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 17px;
  padding-left: 10px;
  font-family: "Montserrat";
  border: 1px solid black;
}

.profile-lastname {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 12px;
  font-size: 17px;
  padding-left: 10px;
  font-family: "Montserrat";
  border: 1px solid black;
}

.country-code-dropdown {
  width: 100px;
  height: 45px;
  font-size: 17px;
  font-family: "Montserrat";
  border: 1px solid black;
  margin-bottom: 10px;
}

.profile-mobile {
  width: 190px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  padding-left: 10px;
  font-family: "Montserrat";
  border: 1px solid black;
  margin-left: 8px;
}

.gender-dropdown {
  width: 310px;
  height: 45px;
  font-size: 17px;
  font-family: "Montserrat";
  border: 1px solid black;
  margin-left: 8px;
  margin-right: 8px;
}

.profile-otp {
  width: 170px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  padding-left: 10px;
  font-family: "Montserrat";
  border: 1px solid black;
  margin-bottom: 10px;
  margin-left: 2px;
}

.profile-mobile-verify-field {
  width: 320px;
  display: flex;
  align-items: flex-start;
}

.verify-button {
  width: 320px;
  height: 45px;
  border-radius: 5px;
  background-color: rgb(103, 57, 232);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid rgb(103, 57, 232);
  font-size: 17px;
  font-family: "Montserrat";
  margin-bottom: 20px;
}

.verify-button:hover {
  background-color: rgb(139, 105, 234);
}

.ok-button {
  width: 80px;
  height: 45px;
  border-radius: 5px;
  background-color: rgb(103, 57, 232);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid rgb(103, 57, 232);
  font-size: 17px;
  font-family: "Montserrat";
  margin-left: 10px;
  margin-bottom: 10px;
}

.student-button {
  width: 144px;
  height: 45px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  font-size: 17px;
  font-family: "Montserrat";
  margin-top: 10px;
}

.teacher-button {
  width: 144px;
  height: 45px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  font-size: 17px;
  font-family: "Montserrat";
  margin-right: 20px;
  padding-left: 10px;
  margin-top: 10px;
}

.teacher-button.selected,
.student-button.selected {
  background-color: #0b7c93;
  color: #fff;
  border-color: #0b7c93;
}

.proceed-button {
  width: 315px;
  height: 50px;
  border-radius: 5px;
  background-color: rgb(103, 57, 232);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid rgb(103, 57, 232);
  margin-bottom: 10px;
  font-size: 17px;
  font-family: "Montserrat";
}

.proceed-button:hover {
  background-color: rgb(139, 105, 234);
}

.profile-code-text {
  color: black;
  font-size: 11px;
  margin-top: 0px;
}

.profile-verify-text {
  color: green;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.resend-button {
  font-size: 10px !important;
  font-weight: 700 !important;
}

.profile-error {
  color: red;
  font-size: 11px;
  margin-top: 0px;
}