.shd-container {
  background-image: url("../../assets/images/sd-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.shd-navbar {
  width: 100%;
}

.shd-homework-card {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 12px;
}

.arrowback {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}

.arrowback-icon {
  padding: 0;
  margin-right: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .arrowback {
    margin-bottom: 15px;
  }
}