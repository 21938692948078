.thd-container {
  background-image: url("../../assets/images/homework-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  /* Use min-height instead of height to allow content to expand */
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.thd-navbar {
  width: 100%;
}

.thd-homework-card {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 12px;
}

.thd-add-homework-btn {
  margin-top: 30px !important;
  margin-left: 20px !important;
  background-color: black !important;
  width: 250px !important;
  height: 50px !important;
}

.thd-add-homework-btn-holder {
  width: 100%;
  display: flex;
  justify-content: center;
}

.arrowback {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}

.arrowback-icon {
  padding: 0;
  margin-right: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .arrowback {
    margin-bottom: 15px;
  }
}