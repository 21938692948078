body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', monospace;
}

@font-face {
  font-family: "Cocon";
  src: local("CoconBold"),
    url("./assets/fonts/cocon/cocon-bold.otf") format("truetype");
  font-weight: bold;
}
