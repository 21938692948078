.email-verify-container {
  background-image: url("../../assets/images/email-verify-bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.email-verify-card {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 50vh;
  background-color: white;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.email-verify-text-1 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
}

.email-verify-text-2 {
  text-align: center;
  font-family: "Cocon";
  font-size: 25px;
  color: rgb(87, 64, 236);
}

.email-verify-text-3 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
}

.email-icon {
  background-image: url("../../assets/images/email-icon.png");
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
}

@media (max-width: 900px) {
  .email-verify-container {
    background-image: none;
  }

  .email-verify-card {
    width: 100vw;
    height: 100vh;
  }

  .email-verify-text-2 {
    font-size: 20px;
  }
}
