.sad-container {
    background-image: url("../../assets/images/sd-bg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    overflow: auto;
  }
  
  .sad-navbar {
    width: 100vw;
    margin-bottom: 20px;
  }
  
  .filter-container {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
  }
  
  .table-container {
    width: 100%;
    max-width: 800px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .tablePlaceholder {
    flex-grow: 1;
  }
  
  .tableHeader {
    background-color: whitesmoke !important;
    color: black !important;
    font-weight: bolder !important;
  }
  
  .tableCell {
    background-color: whitesmoke !important;
    color: black !important;
    font-weight: bolder !important;
  }