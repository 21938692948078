.register-container {
  background-image: url("../../assets/images/register-bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
}

.register-card {
  display: flex;
  width: 80vw;
  height: 80vh;
  background-color: white;
  border-radius: 20px;
}

.register-image {
  width: 60vw;
  height: 80vh;
  background-image: url("../../assets/images/register-bg-1.jpg");
  background-size: cover;
  background-position: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.register-form {
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: auto;
}

.register-logo {
  font-family: "Cocon";
  font-size: 50px;
  width: 100%;
  text-align: center;
  color: #6423f2;
  margin-bottom: 20px;
}

.register-username {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 17px;
  padding-left: 10px;
  font-family: "Montserrat";
  border: 1px solid black;
}

.register-password {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  padding-left: 10px;
  font-family: "Montserrat";
  margin-bottom: 10px;
  border: 1px solid black;
}

.re-password {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  padding-left: 10px;
  margin-bottom: 20px;
  font-family: "Montserrat";
  border: 1px solid black;
}

.register-title {
  margin-top: 0;
  font-family: "Montserrat";
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.register-button {
  width: 320px;
  height: 50px;
  border-radius: 5px;
  background-color: rgb(103, 57, 232);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid rgb(103, 57, 232);
  margin-bottom: 10px;
  font-size: 17px;
  font-family: "Montserrat";
}

.register-button:hover {
  background-color: #454546;
}

#google-button {
  width: 320px !important;
  border-radius: 5px !important;
  border: 1px solid #000000 !important;
  font-family: "Montserrat" !important;
}

.login-link {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-family: "Montserrat";
}

.login-link-button {
  text-decoration: none;
  color: rgb(103, 57, 232);
  font-weight: 500;
  font-family: "Montserrat";
}

@media (max-width: 700px) {
  .register-container {
    background-image: none;
  }

  .register-image {
    display: none;
  }

  .register-card {
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  .register-form {
    border-radius: 0px;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  .register-username,
  .register-password,
  .register-button,
  .re-password {
    width: 260px;
  }

  #google-button {
    width: 260px !important;
  }
}

@media (min-width: 700px) and (max-width: 1400px) {
  .register-image {
    display: none;
  }

  .register-form {
    width: 100vw;
    height: 80vh;
    border-radius: 20px;
    overflow: auto;
  }
}
