.sd-container {
  background-image: url("../../assets/images/sd-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  overflow: auto;
}

.sd-navbar {
  width: 100vw;
}

.sd-class-card {
  width: 100vw;
  margin-top: 70px;
}

.sd-add-class-btn {
  margin-top: 30px !important;
  margin-left: 20px !important;
  background-color: black !important;
  width: 250px !important;
  height: 50px !important;
}

.sd-dashboard-header {
  margin-top: 20px !important;
  margin-left: 20px !important;
  margin-bottom: -20px !important;
  font-family: "Cocon" !important;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.sd-add-class-btn-holder {
  width: 100vw;
  display: flex;
  justify-content: center;
}
